<template>
  <div class="page-layout">
    <section class="intro intro--no-waves">
      <div class="container">
        <Navigation :currentTab="pageData.pageKey" />

        <div class="container-blog container text-left">
          <!-- Show title dynamically here -->
          <h1 class="trilon-title">
            {{ pageData.pageTitle }}
            <span v-if="!pageData.titleEnd" class="trilon-pink">.</span>
            <span v-if="pageData.titleEnd" class="trilon-pink">{{
              pageData.titleEnd
            }}</span>
          </h1>
        </div>
      </div>
    </section>

    <div class="intro-waves"></div>
    <!-- <section class="container wave-wrapper-upper-only blog-post-wrapper"> -->
    <section class="wave-wrapper">
      <section
        class="dark-bg"
        :class="{
          'blog-list-wrapper': pageData.hideCountriesVisited,
          'page-wrapper': !pageData.hideCountriesVisited,
        }"
      >
        <div class="container-blog-content container">
          <div
            class="dark-bg"
            :class="{ 'page-container': !pageData.hideCountriesVisited }"
          >
            <!-- <b-breadcrumb :items="breadcrumbs" /> -->

            <!-- where Nuxt is going to render everything into -->
            <slot />
            <!-- where Nuxt is going to render everything into -->
          </div>
        </div>
      </section>

    </section>

    <div class="dark-bg">
      <div
        v-if="pageData.showTalks"
        class="container our-talks-wrapper"
        :class="{ 'our-talks-wrapper--no-bg': pageData.hideCountriesVisited }"
      >
        <CountriesVisited
          v-if="!pageData.hideCountriesVisited"
        ></CountriesVisited>
        <OurTalks></OurTalks>
      </div>

      <WhatWeDo
        v-if="!pageData.hideWhatWeDo"
        class="pb-5"
        :atTrilon="true"
        :disableMargin="!pageData.hideCountriesVisited"
      ></WhatWeDo>
    </div>
    <ContactUs v-if="!pageData.hideContactUs" class="pt-5"></ContactUs>

    <Footer></Footer>
  </div>
</template>

<script setup>
const route = useRoute();
const pageData = ref(route.meta)
useHead({
  titleTemplate: (t) => `${t} - Trilon Consulting`
})
</script>

<style lang="scss" scoped>
// For debugging CSS layers
// * { background-color: rgba(255,0,0,.2); }
// * * { background-color: rgba(0,255,0,.2); }
// * * * { background-color: rgba(0,0,255,.2); }
// * * * * { background-color: rgba(255,0,255,.2); }
// * * * * * { background-color: rgba(0,255,255,.2); }
// * * * * * * { background-color: rgba(255,255,0,.2); }
// * * * * * * * { background-color: rgba(255,0,0,.2); }
// * * * * * * * * { background-color: rgba(0,255,0,.2); }
// * * * * * * * * * { background-color: rgba(0,0,255,.2); }
</style>
